// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSessionSummaryToggleUserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  optOutTranscription: boolean;
};

export type GenerateSessionSummaryToggleUpdateUserMutationVariables =
  Types.Exact<{
    user?: Types.InputMaybe<Types.UserInput>;
  }>;

export type GenerateSessionSummaryToggleUpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    optOutTranscription: boolean;
  };
};

export type GenerateSessionSummaryToggleUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GenerateSessionSummaryToggleUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    optOutTranscription: boolean;
  } | null;
};

export const GenerateSessionSummaryToggleUserFragmentDoc = gql`
  fragment GenerateSessionSummaryToggle_User on User {
    id
    firstName
    lastName
    optOutTranscription
  }
`;
export const GenerateSessionSummaryToggleUpdateUserDocument = gql`
  mutation GenerateSessionSummaryToggle_UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      ...GenerateSessionSummaryToggle_User
    }
  }
  ${GenerateSessionSummaryToggleUserFragmentDoc}
`;
export type GenerateSessionSummaryToggleUpdateUserMutationFn =
  Apollo.MutationFunction<
    GenerateSessionSummaryToggleUpdateUserMutation,
    GenerateSessionSummaryToggleUpdateUserMutationVariables
  >;

/**
 * __useGenerateSessionSummaryToggleUpdateUserMutation__
 *
 * To run a mutation, you first call `useGenerateSessionSummaryToggleUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSessionSummaryToggleUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSessionSummaryToggleUpdateUserMutation, { data, loading, error }] = useGenerateSessionSummaryToggleUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGenerateSessionSummaryToggleUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSessionSummaryToggleUpdateUserMutation,
    GenerateSessionSummaryToggleUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSessionSummaryToggleUpdateUserMutation,
    GenerateSessionSummaryToggleUpdateUserMutationVariables
  >(GenerateSessionSummaryToggleUpdateUserDocument, options);
}
export type GenerateSessionSummaryToggleUpdateUserMutationHookResult =
  ReturnType<typeof useGenerateSessionSummaryToggleUpdateUserMutation>;
export type GenerateSessionSummaryToggleUpdateUserMutationResult =
  Apollo.MutationResult<GenerateSessionSummaryToggleUpdateUserMutation>;
export type GenerateSessionSummaryToggleUpdateUserMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateSessionSummaryToggleUpdateUserMutation,
    GenerateSessionSummaryToggleUpdateUserMutationVariables
  >;
export const GenerateSessionSummaryToggleUserDocument = gql`
  query GenerateSessionSummaryToggle_User {
    user {
      ...GenerateSessionSummaryToggle_User
    }
  }
  ${GenerateSessionSummaryToggleUserFragmentDoc}
`;

/**
 * __useGenerateSessionSummaryToggleUserQuery__
 *
 * To run a query within a React component, call `useGenerateSessionSummaryToggleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSessionSummaryToggleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSessionSummaryToggleUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSessionSummaryToggleUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >(GenerateSessionSummaryToggleUserDocument, options);
}
export function useGenerateSessionSummaryToggleUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >(GenerateSessionSummaryToggleUserDocument, options);
}
export function useGenerateSessionSummaryToggleUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateSessionSummaryToggleUserQuery,
    GenerateSessionSummaryToggleUserQueryVariables
  >(GenerateSessionSummaryToggleUserDocument, options);
}
export type GenerateSessionSummaryToggleUserQueryHookResult = ReturnType<
  typeof useGenerateSessionSummaryToggleUserQuery
>;
export type GenerateSessionSummaryToggleUserLazyQueryHookResult = ReturnType<
  typeof useGenerateSessionSummaryToggleUserLazyQuery
>;
export type GenerateSessionSummaryToggleUserSuspenseQueryHookResult =
  ReturnType<typeof useGenerateSessionSummaryToggleUserSuspenseQuery>;
export type GenerateSessionSummaryToggleUserQueryResult = Apollo.QueryResult<
  GenerateSessionSummaryToggleUserQuery,
  GenerateSessionSummaryToggleUserQueryVariables
>;
