import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { parseQs } from '../query-params';

import { trackGtm } from './gtm';
import mxpnl from './mixpanel';

const trimQs = (url: string) => url.split('?')[0];

export const usePageView = (): void => {
  const router = useRouter();

  useEffect(() => {
    const eventData = {
      pageTitle: document.title,
      pageUrl: trimQs(document.location.pathname),
      pageKey: router.pathname,
      params: router.query,
      ...parseQs(document.location.search),
    };
    trackGtm('virtualPageView', eventData);
    mxpnl.track('pageView', eventData);
    // do not add router.pathname or router.query to the dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRouteChange = useCallback(
    (pageUrl: string) => {
      const eventData = {
        pageTitle: document.title,
        pageUrl: trimQs(pageUrl),
        pageKey: router.pathname,
        params: router.query,
        ...parseQs(pageUrl),
      };
      trackGtm('virtualPageView', eventData);
      mxpnl.track('pageView', eventData);
    },
    [router.pathname, router.query],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);
};
