import { Tooltip } from '@leland-dev/leland-ui-library';
import React, { type ReactNode } from 'react';

interface SwitchInputProps {
  label: ReactNode;
  isChecked: boolean;
  onToggle?: () => void;
  className?: string;
  labelClassName?: string;
  switchClassName?: string;
  destructive?: boolean;
  disabled?: boolean;
  toolTipForDisabled?: string;
  hideLabel?: boolean;
  isSmall?: boolean;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  isChecked,
  onToggle,
  className = '',
  labelClassName,
  switchClassName,
  destructive,
  disabled,
  toolTipForDisabled,
  hideLabel,
  isSmall,
}) => {
  const switchToggle = (
    <button
      className={`flex items-center space-x-2 ${className}`}
      type="button"
      onClick={onToggle}
      disabled={disabled || onToggle == null}
    >
      <div
        className={`relative ${
          isSmall ? 'h-5 w-9' : 'h-6 w-11'
        } shrink-0 rounded-full transition-colors ${
          isChecked
            ? destructive
              ? 'bg-leland-red'
              : 'bg-leland-primary'
            : 'bg-leland-gray-stroke'
        } ${disabled ? 'opacity-50' : ''} ${switchClassName}`}
      >
        <span
          id="switch"
          className={`absolute left-0.5 top-0.5 ${
            isSmall ? 'size-4' : 'size-5'
          } rounded-full border-white bg-white transition-all ${
            isChecked ? 'translate-x-full' : ''
          }`}
        />
      </div>
      {hideLabel ? null : (
        <p
          className={`select-none leading-snug text-leland-gray-dark ${labelClassName}`}
        >
          {label}
        </p>
      )}
    </button>
  );

  return disabled && toolTipForDisabled ? (
    <Tooltip content={toolTipForDisabled}>{switchToggle}</Tooltip>
  ) : (
    switchToggle
  );
};

export default SwitchInput;
