import { ReferralType } from '../__generated-gql-types__/globalTypes';

export const REFERRAL_TYPE_MAP: Record<ReferralType, string> = {
  [ReferralType.COACH_REFER_CUSTOMER]: '1',
  [ReferralType.COACH_REFER_COACH]: '2',
  [ReferralType.CUSTOMER_REFER_CUSTOMER]: '3',
  [ReferralType.CUSTOMER_REFER_COACH]: '4',
};

export const REFERRAL_TYPE_MAP_REVERSE = Object.fromEntries(
  Object.entries(REFERRAL_TYPE_MAP).map(([key, value]) => [value, key]),
);

export const getReferralTypeFromString = (
  param: string | undefined,
): ReferralType | undefined => {
  if (param === undefined) return undefined;
  return REFERRAL_TYPE_MAP_REVERSE[param] as ReferralType;
};

export const CUSTOMER_REFERRAL_CREDIT = 5000;
