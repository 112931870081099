import type Rollbar from 'rollbar';

import { rollbar } from './rollbar';

enum ExceptionLevel {
  ERROR,
  WARNING,
  INFO,
}

// TODO: Verify stack trace is preserved when logging
export const logException = (
  message: string,
  level: ExceptionLevel = ExceptionLevel.ERROR,
): void => {
  _logRollbar(message, level);
};

const _logRollbar = (
  message: string,
  level: ExceptionLevel = ExceptionLevel.ERROR,
): Rollbar.LogResult | undefined => {
  if (rollbar === null) {
    console.warn('Rollbar has not been initialized yet', { message, level });
    return;
  }

  if (level === ExceptionLevel.ERROR) {
    return rollbar?.error(message);
  }

  if (level === ExceptionLevel.WARNING) {
    return rollbar?.warn(message);
  }

  if (level === ExceptionLevel.INFO) {
    return rollbar?.log(message);
  }
};
