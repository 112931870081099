import { Auth0Provider } from '@auth0/auth0-react';
import React, { type FC, type PropsWithChildren } from 'react';

import { AUTH_REDIRECT_URI } from '../utils/constants';

const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
const AUTH0_ISSUER_BASE_URL = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL;
const LELAND_API_URL = process.env.NEXT_PUBLIC_LELAND_API_URL;
if (!AUTH0_CLIENT_ID) {
  throw new Error('Missing env var: NEXT_PUBLIC_AUTH0_CLIENT_ID');
}
if (!AUTH0_ISSUER_BASE_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL');
}
if (!LELAND_API_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_LELAND_API_URL');
}

export const Auth0ProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH0_ISSUER_BASE_URL}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: AUTH_REDIRECT_URI,
        audience: LELAND_API_URL,
      }}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};
