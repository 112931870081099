import { compact } from '@leland-dev/leland-ui-library';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

import { type ReferralType } from '../__generated-gql-types__/globalTypes';

import { APPLICANT_SITE_URL, isServer } from './constants';
import { REFERRAL_TYPE_MAP } from './referrals';
import { LELAND_PLUS_REFERRING_COACH_KEY, LelandLocalStorage } from './storage';

export const redirectToLogin = async (router: NextRouter): Promise<boolean> => {
  return router.push(getLoginUrlObject(router));
};

export const redirectToApplicantSignup = async (
  router: NextRouter,
  redirectUrl?: string,
  queryParams?: Record<string, Optional<string>>,
): Promise<void> => {
  await router.push(
    getUrlObject('/signup', {
      redirect_url: redirectUrl ?? router.asPath,
      ...queryParams,
    }),
  );
};

export const getLoginUrlObject = (router: NextRouter): UrlObject => {
  const query = isServer
    ? {
        ...router.query,
      }
    : {
        ...router.query,
        redirect_url: window.location.href,
      };
  return getUrlObject('/login', query);
};

export const getUrlObject = (
  pathname: string,
  query: UrlObject['query'],
  hash?: UrlObject['hash'],
): UrlObject => ({
  // trim query params from pathname
  pathname: pathname.replace(/[?#].*/, ''),
  query,
  hash,
});

export const getUrlString = (
  url: string,
  queryParams: UrlObject['query'],
  { useHash = false }: { useHash?: boolean } = {},
): string => {
  if (isEmpty(queryParams)) {
    return url;
  }

  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  const paramsString = Object.entries(omitBy(queryParams, isNil))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter(compact)
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
  /* eslint-enable @typescript-eslint/no-unsafe-argument */

  const char = useHash ? '#' : '?';
  return `${url}${url.includes(char) ? '&' : char}${paramsString}`;
};

export const getStripePaymentLinkObject = (
  router: NextRouter,
  email: string,
): UrlObject | undefined => {
  if (router.query.payment_link) {
    if (router.query.coach) {
      LelandLocalStorage.setItem(
        LELAND_PLUS_REFERRING_COACH_KEY,
        router.query.coach as string,
      );
    }
    return getUrlObject(router.query.payment_link as string, {
      prefilled_email: email,
    });
  }
};

export const getCustomerReferralUrl = (
  referralCode: string,
  referralType: ReferralType,
): string => {
  return `${APPLICANT_SITE_URL}/signup?referralCode=${referralCode}&referralType=${REFERRAL_TYPE_MAP[referralType]}`;
};
