import { isServer } from './constants';

const DUMMY_STORAGE: Storage = {
  length: 0,
  clear() {
    return;
  },
  getItem() {
    return null;
  },
  key() {
    return null;
  },
  removeItem() {
    return;
  },
  setItem() {
    return;
  },
};

enum StorageType {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
}

class LelandStorage {
  private readonly storage: Storage;

  constructor(
    storage: StorageType,
    private readonly prefix: string,
  ) {
    this.storage = isServer ? DUMMY_STORAGE : window[storage];
  }

  private prefixKey(key: string): string {
    return `${this.prefix}-${key}`;
  }

  private getUnparsedItem(key: string): Nullable<string> {
    return this.storage.getItem(this.prefixKey(key));
  }

  setItem<T>(key: string, item: T): void {
    this.storage.setItem(this.prefixKey(key), JSON.stringify(item));
  }

  getItem<T>(key: string): Nullable<T>;
  getItem<T>(key: string, fallback: T): T;
  getItem<T>(key: string, fallback?: T): Nullable<T> {
    const data: Nullable<string> = this.getUnparsedItem(key);

    if (data) {
      try {
        const value = JSON.parse(data) as T;
        return value;
      } catch {
        // noop
      }
    }

    return fallback ?? null;
  }

  removeItem(key: string): void {
    this.storage.removeItem(this.prefixKey(key));
  }

  exists(key: string): boolean {
    return !!this.getUnparsedItem(key);
  }
}

export const LelandLocalStorage = new LelandStorage(StorageType.LOCAL, 'lls');
export const LelandSessionStorage = new LelandStorage(
  StorageType.SESSION,
  'lss',
);

// Keys - please keep in alphabetical order and unique
export const AUTH_CARD_EMAIL_KEY = 'AUTH_CARD_EMAIL';
export const DISMISSED_BANNERS_KEY = 'dismissedBanners';
export const ESSAY_PROMPT_LOCAL_STORAGE_KEY = 'essayPrompt';
export const FIRST_NAME_KEY = 'firstName';
export const LAST_NAME_KEY = 'lastName';
export const LELAND_PLUS_REFERRING_COACH_KEY = 'lelandPlusReferringCoach';
export const PREVIOUS_ROUTE_KEY = 'previousRoute';
export const REFERRAL_CODE_KEY = 'referralCode';
export const REFERRAL_TYPE_KEY = 'referralType';
export const RETURN_TO_KEY = 'returnTo';
