import type { Scalars } from '../__generated-gql-types__/globalTypes';

// format of category urn is `urn:category:{{ slug }}`
// https://github.com/leland-dev/leland-backend/blob/main/src/main/java/com/leland/urn/CategoryUrn.java
const CATEGORY_URN_PREFIX = 'urn:category:';
export const categorySlugToUrn = (slug: string) =>
  `${CATEGORY_URN_PREFIX}${slug}`;

export const categoryUrnToSlug = (urn: string) => {
  return urn.replace(CATEGORY_URN_PREFIX, '');
};

export const getSubCategoryTitle = (
  subCategoryTitle: Possible<string>,
): string => subCategoryTitle ?? 'Specialty';

const SUBCATEGORY_URN_PREFIX = 'urn:subCategory:';

export const subCategorySlugToUrn = (slug: string) =>
  `${SUBCATEGORY_URN_PREFIX}${slug}`;

export const subCategoryUrnToSlug = (
  urn: Scalars['SubCategoryUrn']['output'],
) => urn.replace(SUBCATEGORY_URN_PREFIX, '');

export const CATEGORY_REMAP: Record<string, string> = {
  undergrad: 'college',
};

export const categoryRedirectMapToSlug = (
  categorySlug: string,
  hasActiveCoach: boolean | undefined | null,
): string => {
  if (hasActiveCoach) {
    return categorySlug;
  }
  switch (categorySlug) {
    case 'accounting':
    case 'equity-research':
    case 'f-p-and-a':
    case 'financial-planning-advising':
    case 'hedge-fund':
      return 'private-equity';
    case 'customer-success':
    case 'customer-support':
    case 'design':
    case 'human-resources':
    case 'real-estate':
      return 'general-career';
    case 'cyber-security':
    case 'engineering-other':
      return 'software-engineering';
    case 'dental-career':
    case 'dentistry':
    case 'dat':
      return 'dental-school';
    case 'medicine-career':
      return 'medical-school';
    case 'product-operations':
      return 'business-strategy-and-operations';
    case 'sales-and-trading':
      return 'sales-strategy-and-operations';
    case 'technical-program-management':
      return 'product-management';
    default:
      return categorySlug;
  }
};
