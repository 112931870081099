// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { CoachingSessionAlertBannerCoachingSessionFragmentDoc } from '../../components/coaching-session/__generated-gql-types__/CoachingSessionAlertBanner.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthContextUserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  pictureLink?: string | null;
  email: string;
  createdAt: number;
  phoneNumber?: {
    __typename?: 'PhoneNumber';
    countryCode: string;
    callingCountryCode: string;
    nationalFormat: string;
  } | null;
  applicant?: {
    __typename?: 'Applicant';
    id: string;
    newExperience?: boolean | null;
    moneyCreditAvailable: number;
    timeCreditAvailable: number;
    upcomingEnrolledSessions?: Array<{
      __typename?: 'CoachingSession';
      id: string;
      startAt: number;
      endAt: number;
      content:
        | {
            __typename?: 'Class';
            id: string;
            name?: string | null;
            slug?: string | null;
            isFreeEvent?: boolean | null;
          }
        | { __typename?: 'OneOnOneSession'; title: string };
      coach: {
        __typename?: 'Coach';
        id: string;
        user: { __typename?: 'User'; id: string; firstName: string };
      };
    }> | null;
    lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
  } | null;
  coach?: { __typename?: 'Coach'; id: string } | null;
  impersonator?: { __typename?: 'User'; id: string } | null;
  userSecrets?: {
    __typename?: 'UserSecrets';
    id: string;
    firstContact?: {
      __typename?: 'FirstContact';
      gclid?: string | null;
      source?: string | null;
      medium?: string | null;
      campaign?: string | null;
      referrer?: string | null;
    } | null;
  } | null;
  source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
};

export type AuthContextQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuthContextQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    email: string;
    createdAt: number;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      newExperience?: boolean | null;
      moneyCreditAvailable: number;
      timeCreditAvailable: number;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              slug?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        coach: {
          __typename?: 'Coach';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
      }> | null;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
    impersonator?: { __typename?: 'User'; id: string } | null;
    userSecrets?: {
      __typename?: 'UserSecrets';
      id: string;
      firstContact?: {
        __typename?: 'FirstContact';
        gclid?: string | null;
        source?: string | null;
        medium?: string | null;
        campaign?: string | null;
        referrer?: string | null;
      } | null;
    } | null;
    source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
  } | null;
};

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logoutUrl: string };

export type RequestLoginVerificationCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type RequestLoginVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestLoginSecurityCode: boolean;
};

export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    email: string;
    createdAt: number;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      newExperience?: boolean | null;
      moneyCreditAvailable: number;
      timeCreditAvailable: number;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              slug?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        coach: {
          __typename?: 'Coach';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
      }> | null;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
    impersonator?: { __typename?: 'User'; id: string } | null;
    userSecrets?: {
      __typename?: 'UserSecrets';
      id: string;
      firstContact?: {
        __typename?: 'FirstContact';
        gclid?: string | null;
        source?: string | null;
        medium?: string | null;
        campaign?: string | null;
        referrer?: string | null;
      } | null;
    } | null;
    source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
  };
};

export type RequestSignupVerificationCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type RequestSignupVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestSignupSecurityCode: boolean;
};

export type SignupMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  applicant: Types.ApplicantInput;
  userSecrets: Types.UserSecretsInput;
  autoNewExperience: Types.Scalars['Boolean']['input'];
  redeemCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    email: string;
    createdAt: number;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      newExperience?: boolean | null;
      moneyCreditAvailable: number;
      timeCreditAvailable: number;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              slug?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        coach: {
          __typename?: 'Coach';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
      }> | null;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
    impersonator?: { __typename?: 'User'; id: string } | null;
    userSecrets?: {
      __typename?: 'UserSecrets';
      id: string;
      firstContact?: {
        __typename?: 'FirstContact';
        gclid?: string | null;
        source?: string | null;
        medium?: string | null;
        campaign?: string | null;
        referrer?: string | null;
      } | null;
    } | null;
    source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
  };
};

export type SignupAsApplicantMutationVariables = Types.Exact<{
  applicant: Types.ApplicantInput;
}>;

export type SignupAsApplicantMutation = {
  __typename?: 'Mutation';
  signupAsApplicant: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    email: string;
    createdAt: number;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      newExperience?: boolean | null;
      moneyCreditAvailable: number;
      timeCreditAvailable: number;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              slug?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        coach: {
          __typename?: 'Coach';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
      }> | null;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
    impersonator?: { __typename?: 'User'; id: string } | null;
    userSecrets?: {
      __typename?: 'UserSecrets';
      id: string;
      firstContact?: {
        __typename?: 'FirstContact';
        gclid?: string | null;
        source?: string | null;
        medium?: string | null;
        campaign?: string | null;
        referrer?: string | null;
      } | null;
    } | null;
    source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
  };
};

export const AuthContextUserFragmentDoc = gql`
  fragment AuthContext_User on User {
    id
    firstName
    lastName
    pictureLink
    email
    createdAt
    phoneNumber {
      countryCode
      callingCountryCode
      nationalFormat
    }
    applicant {
      id
      upcomingEnrolledSessions {
        ...CoachingSessionAlertBanner_CoachingSession
      }
      lelandPlus {
        active
      }
      newExperience
      moneyCreditAvailable
      timeCreditAvailable
    }
    coach {
      id
    }
    impersonator {
      id
    }
    userSecrets {
      id
      firstContact {
        gclid
        source
        medium
        campaign
        referrer
      }
    }
    source {
      type
    }
  }
  ${CoachingSessionAlertBannerCoachingSessionFragmentDoc}
`;
export const AuthContextDocument = gql`
  query AuthContext {
    user {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;

/**
 * __useAuthContextQuery__
 *
 * To run a query within a React component, call `useAuthContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthContextQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export function useAuthContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export function useAuthContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export type AuthContextQueryHookResult = ReturnType<typeof useAuthContextQuery>;
export type AuthContextLazyQueryHookResult = ReturnType<
  typeof useAuthContextLazyQuery
>;
export type AuthContextSuspenseQueryHookResult = ReturnType<
  typeof useAuthContextSuspenseQuery
>;
export type AuthContextQueryResult = Apollo.QueryResult<
  AuthContextQuery,
  AuthContextQueryVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logoutUrl
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RequestLoginVerificationCodeDocument = gql`
  mutation RequestLoginVerificationCode($email: String!) {
    requestLoginSecurityCode(email: $email)
  }
`;
export type RequestLoginVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestLoginVerificationCodeMutation,
  RequestLoginVerificationCodeMutationVariables
>;

/**
 * __useRequestLoginVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestLoginVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLoginVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLoginVerificationCodeMutation, { data, loading, error }] = useRequestLoginVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestLoginVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >(RequestLoginVerificationCodeDocument, options);
}
export type RequestLoginVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestLoginVerificationCodeMutation
>;
export type RequestLoginVerificationCodeMutationResult =
  Apollo.MutationResult<RequestLoginVerificationCodeMutation>;
export type RequestLoginVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >;
export const LoginDocument = gql`
  mutation Login($email: String!, $code: String!) {
    login(email: $email, code: $code) {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RequestSignupVerificationCodeDocument = gql`
  mutation RequestSignupVerificationCode($email: String!) {
    requestSignupSecurityCode(email: $email)
  }
`;
export type RequestSignupVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestSignupVerificationCodeMutation,
  RequestSignupVerificationCodeMutationVariables
>;

/**
 * __useRequestSignupVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestSignupVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSignupVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSignupVerificationCodeMutation, { data, loading, error }] = useRequestSignupVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestSignupVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >(RequestSignupVerificationCodeDocument, options);
}
export type RequestSignupVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestSignupVerificationCodeMutation
>;
export type RequestSignupVerificationCodeMutationResult =
  Apollo.MutationResult<RequestSignupVerificationCodeMutation>;
export type RequestSignupVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $code: String!
    $firstName: String!
    $lastName: String!
    $applicant: ApplicantInput!
    $userSecrets: UserSecretsInput!
    $autoNewExperience: Boolean!
    $redeemCode: String
  ) {
    signup(
      email: $email
      code: $code
      firstName: $firstName
      lastName: $lastName
      applicant: $applicant
      userSecrets: $userSecrets
      autoNewExperience: $autoNewExperience
      redeemCode: $redeemCode
    ) {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      applicant: // value for 'applicant'
 *      userSecrets: // value for 'userSecrets'
 *      autoNewExperience: // value for 'autoNewExperience'
 *      redeemCode: // value for 'redeemCode'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options,
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const SignupAsApplicantDocument = gql`
  mutation SignupAsApplicant($applicant: ApplicantInput!) {
    signupAsApplicant(applicant: $applicant) {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type SignupAsApplicantMutationFn = Apollo.MutationFunction<
  SignupAsApplicantMutation,
  SignupAsApplicantMutationVariables
>;

/**
 * __useSignupAsApplicantMutation__
 *
 * To run a mutation, you first call `useSignupAsApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAsApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAsApplicantMutation, { data, loading, error }] = useSignupAsApplicantMutation({
 *   variables: {
 *      applicant: // value for 'applicant'
 *   },
 * });
 */
export function useSignupAsApplicantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupAsApplicantMutation,
    SignupAsApplicantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupAsApplicantMutation,
    SignupAsApplicantMutationVariables
  >(SignupAsApplicantDocument, options);
}
export type SignupAsApplicantMutationHookResult = ReturnType<
  typeof useSignupAsApplicantMutation
>;
export type SignupAsApplicantMutationResult =
  Apollo.MutationResult<SignupAsApplicantMutation>;
export type SignupAsApplicantMutationOptions = Apollo.BaseMutationOptions<
  SignupAsApplicantMutation,
  SignupAsApplicantMutationVariables
>;
