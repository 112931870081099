import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { omit } from 'lodash-es';
import { useRouter } from 'next/router';
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { trackGtm } from '../utils/analytics/gtm';
import mxpnl from '../utils/analytics/mixpanel';
import { LelandAutoNewExperience } from '../utils/auto-new-experience';
import { LelandLocalStorage, RETURN_TO_KEY } from '../utils/storage';
import { getUrlObject } from '../utils/url';

import {
  AuthContextDocument,
  type AuthContextUserFragment,
  type SignupAsApplicantMutationVariables,
  type SignupMutationVariables,
  useAuthContextQuery,
  useLoginMutation,
  useLogoutMutation,
  useRequestLoginVerificationCodeMutation,
  useRequestSignupVerificationCodeMutation,
  useSignupAsApplicantMutation,
  useSignupMutation,
} from './__generated-gql-types__/AuthContext.generated';

export interface AuthContext {
  currentUser: Possible<AuthContextUserFragment>;
  errorLoadingUser?: Error;
  isLoadingUser: boolean;
  isImpersonating: boolean;
  isNewSubscriptionExperience: boolean;
  /**
   * In most cases, current user would automatically be updated by Apollo, unless the cached one is null.
   */
  setCurrentUser: (data: AuthContextUserFragment) => void;
  /**
   * Used to read the current user outside of the rendering context.
   */
  readCurrentUser: () => Possible<AuthContextUserFragment>;
  redirectToVerifyEmail: (redirectUrl: string) => Promise<boolean>;
  isEmailVerified: boolean | undefined;
  trackSignUp: (userId: string, applicantId?: string) => void;
}

const MISSING_AUTH_PROVIDER = 'You forgot to wrap your app in <AuthProvider>';

export const AuthContext = createContext<AuthContext>({
  get currentUser(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get errorLoadingUser(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get isLoadingUser(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get isImpersonating(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get isNewSubscriptionExperience(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get setCurrentUser(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get readCurrentUser(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get redirectToVerifyEmail(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get isEmailVerified(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
  get trackSignUp(): never {
    throw new Error(MISSING_AUTH_PROVIDER);
  },
});

const trackSignUp = (userId: string, applicantId?: string) => {
  const idsPayload = {
    userId,
    ...(applicantId ? { applicantId } : {}),
  };
  trackGtm('applicantSignUp', idsPayload);
  mxpnl.track('applicantSignUp', idsPayload);
};

export const useLogout = (): {
  logout: () => Promise<void>;
  logoutLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const { logout: logoutWithAuth0 } = useAuth0();
  const [logout, { loading: logoutLoading }] = useLogoutMutation();

  return useMemo(
    () => ({
      logout: async () => {
        await logout();
        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: null },
        });
        await logoutWithAuth0({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
      logoutLoading,
    }),
    [apolloClient, logout, logoutLoading, logoutWithAuth0],
  );
};

interface RequestVerificationCodeReturn {
  requestVerificationCode: (email: string) => Promise<void>;
  verificationCodeLoading: boolean;
}

export const useLogin = (): RequestVerificationCodeReturn & {
  login: (email: string, code: string) => Promise<AuthContextUserFragment>;
  loginLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [login, { loading: loginLoading }] = useLoginMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestLoginVerificationCodeMutation();

  return useMemo(
    () => ({
      login: async (email: string, code: string) => {
        const { data, errors } = await login({
          variables: { email, code },
        });
        if (!data || errors?.length) {
          console.warn(errors);
          throw new Error('Failed to login');
        }

        trackGtm('login', {
          userId: data.login.id,
          ...(data.login.applicant?.id
            ? { applicantId: data.login.applicant.id }
            : {}),
        });

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.login },
        });

        return data.login;
      },
      loginLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestLoginSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error('Failed to request login verification code');
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      login,
      loginLoading,
      requestVerificationCode,
      verificationCodeLoading,
    ],
  );
};

export const useSignup = (): RequestVerificationCodeReturn & {
  signup: (vars: SignupMutationVariables) => Promise<AuthContextUserFragment>;
  signupLoading: boolean;
  signupAsApplicant: (
    input: SignupAsApplicantMutationVariables,
  ) => Promise<AuthContextUserFragment>;
  signupAsApplicantLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [signup, { loading: signupLoading }] = useSignupMutation();
  const [signupAsApplicant, { loading: signupAsApplicantLoading }] =
    useSignupAsApplicantMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestSignupVerificationCodeMutation();
  let isAutoNewExperience: Nullable<boolean>;
  try {
    isAutoNewExperience = LelandAutoNewExperience.getAutoNewExperience();
  } catch {
    isAutoNewExperience = null;
  }
  return useMemo(
    () => ({
      signup: async (variables: SignupMutationVariables) => {
        const { data, errors } = await signup({
          variables: {
            ...variables,
            autoNewExperience: isAutoNewExperience != null,
          },
        });

        if (!data || errors) {
          console.warn(errors);
          throw new Error('Failed to sign up');
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signup },
        });

        trackSignUp(data.signup.id, data.signup.applicant?.id);

        return data.signup;
      },
      signupLoading,
      signupAsApplicant: async (
        variables: SignupAsApplicantMutationVariables,
      ) => {
        const { data, errors } = await signupAsApplicant({ variables });

        if (!data || errors) {
          console.warn(errors);
          throw new Error('Failed to sign up as applicant');
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signupAsApplicant },
        });

        trackSignUp(
          data.signupAsApplicant.id,
          data.signupAsApplicant.applicant?.id,
        );

        return data.signupAsApplicant;
      },
      signupAsApplicantLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestSignupSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error('Failed to request signup verification code');
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      requestVerificationCode,
      signup,
      signupAsApplicant,
      signupAsApplicantLoading,
      signupLoading,
      verificationCodeLoading,
      isAutoNewExperience,
    ],
  );
};

export const useAuth: () => AuthContext = () =>
  useContext<AuthContext>(AuthContext);

const NEW_CUSTOMER_VERIFY_EMAIL_BUFFER = 12 * 3600 * 1000; // 12 hr

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { data, loading, error } = useAuthContextQuery();
  const userRef = useRef(data?.user);
  userRef.current = data?.user;
  const { getIdTokenClaims } = useAuth0();
  const [isEmailVerified, setEmailVerified] = useState<boolean | undefined>();

  const redirectToVerifyEmail = useCallback(
    async (redirectUrl: string) => {
      if (!data?.user || loading) {
        return false;
      }
      const idTokenClaims = await getIdTokenClaims();
      setEmailVerified(idTokenClaims?.email_verified);
      if (
        idTokenClaims &&
        !idTokenClaims.email_verified &&
        !router.pathname.startsWith('/auth/') &&
        !router.pathname.startsWith('/apply') &&
        Date.now() - data.user.createdAt >= NEW_CUSTOMER_VERIFY_EMAIL_BUFFER
      ) {
        LelandLocalStorage.setItem(RETURN_TO_KEY, redirectUrl);

        await router.replace(
          getUrlObject('/auth/verify', {
            redirect_url: redirectUrl,
          }),
        );
        return true;
      }
      return false;
    },
    [getIdTokenClaims, router, data?.user, loading],
  );

  const context: AuthContext = useMemo(
    () => ({
      currentUser: data?.user,
      errorLoadingUser: error,
      isLoadingUser: loading,
      isImpersonating: !!data?.user?.impersonator,
      isNewSubscriptionExperience: !!data?.user?.applicant?.newExperience,
      setCurrentUser: (data: AuthContextUserFragment) => {
        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data },
        });
      },
      readCurrentUser: () => userRef.current,
      redirectToVerifyEmail: redirectToVerifyEmail,
      isEmailVerified: isEmailVerified,
      trackSignUp,
    }),
    [
      apolloClient,
      data?.user,
      error,
      loading,
      redirectToVerifyEmail,
      isEmailVerified,
    ],
  );

  useEffect(() => {
    if (data?.user?.id != null) {
      void redirectToVerifyEmail(router.asPath);

      const idsObj: Record<string, string> = {
        userId: data.user.id,
      };
      if (data.user.applicant?.id) {
        idsObj.userApplicantId = data.user.applicant.id;
      }

      mxpnl.identify(data.user.id);
      mxpnl.setUserProperty({
        $email: data.user.email,
        $first_name: data.user.firstName,
        $last_name: data.user.lastName,
        $avatar: data.user.pictureLink,
        ...idsObj,
        ...omit(data.user.userSecrets?.firstContact ?? {}, '__typename'),
      });

      trackGtm(undefined, idsObj);
    }
  }, [
    data?.user?.applicant?.id,
    data?.user?.email,
    data?.user?.firstName,
    data?.user?.id,
    data?.user?.lastName,
    data?.user?.pictureLink,
    data?.user?.userSecrets?.firstContact,
    redirectToVerifyEmail,
    router.asPath,
  ]);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
