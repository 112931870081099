import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import {
  type LexContextLexFragment,
  useLexContextLexListQuery,
} from './__generated-gql-types__/LexContext.generated';

export enum LexSlug {
  RAMP_AI_GENERATOR = 'ramp-ai-generator',
  FEATURE_APPLICANT_USE_CREDITS = 'feature-applicant-use-credits',
  FEATURE_INBOX_DETAILS_SIDEBAR = 'feature-inbox-details-sidebar',
  FEATURE_APPLICANT_FREE_MONTH = 'feature-applicant-free-month',
  FEATURE_CANCEL_PAUSE_SUBSCRIPTION = 'feature-cancel-pause-subscription',
  LINKEDIN_UPSELL = 'linkedin-upsell',
  FEATURE_MANAGE_SUBSCRIPTION = 'feature-manage-subscription',
  FEATURE_SUBSCRIPTION_HOURLY_PRICE = 'ramp-subscription-hourly-price',
  FEATURE_COACHING_REQUEST = 'feature-coaching-request',
  ASSIGN_UNASSIGNED_ORDER_TO_UNMATCHED_COACH = 'assign-unassigned-order-to-unmatched-coach',
  LEX_PACKAGE_HOURLY_UNIFICATION = 'feature-package-hourly-unification',
  FEATURE_BOOTCAMPS_ON_STACK = 'feature-bootcamps-on-stack',
  SPLIT_PAYMENTS_AT_198 = 'split-payments-at-198',
  UPGRADE_PACKAGE_FLOW = 'upgrade-package-flow',
  FEATURE_CUSTOMER_REFERRAL = 'feature-customer-referral',
  NEW_EVENTS_PAGE = 'new-events-page',
}

// list of Lex slugs to evaluate
const LEX_SLUGS_TO_EVALUATE: LexSlug[] = Object.values(LexSlug);

const MISSING_LEX_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <LexProvider>';

interface LexContext {
  isLexEnabled: (lexKey: LexSlug) => boolean;
  isLexLoading: boolean;
}

export const LexContext = createContext<LexContext>({
  get isLexEnabled(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
  get isLexLoading(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
});

// to prevent potential layout shift, initialize some fully ramped lex slugs by default
// NOTE: should only be temporary until the Lex is removed
const DEFAULT_INIT_LIST: LexContextLexFragment[] = [];

/**
 * LeX is short for Leland Experiment to enable A/B testing.
 * `useLex` to check whether the experiment/feature is ramped to the current user/guest
 */
export const useLex: () => LexContext = () =>
  useContext<LexContext>(LexContext);

export const LexContextProvider: FC<PropsWithChildren> = ({ children }) => {
  // TODO: listen to user data change to reload rampedExperiments
  const { data, loading } = useLexContextLexListQuery({
    variables: { slugs: LEX_SLUGS_TO_EVALUATE },
    skip: LEX_SLUGS_TO_EVALUATE.length === 0,
  });

  const value: LexContext = useMemo(() => {
    const lexIsRampedMap = (data?.lexListBySlugs ?? DEFAULT_INIT_LIST).reduce<
      Record<string, boolean>
    >((map, lex) => {
      map[lex.slug] = lex.isRamped;
      return map;
    }, {});

    return {
      isLexEnabled: (slug) => !!lexIsRampedMap[slug],
      isLexLoading: loading,
    };
  }, [data?.lexListBySlugs, loading]);

  return <LexContext.Provider value={value}>{children}</LexContext.Provider>;
};
