// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
export type CoachingSessionAlertBannerClassFragment = {
  __typename?: 'Class';
  id: string;
  name?: string | null;
  slug?: string | null;
  isFreeEvent?: boolean | null;
};

export type CoachingSessionAlertBannerOneOnOneSessionFragment = {
  __typename?: 'OneOnOneSession';
  title: string;
};

export type CoachingSessionAlertBannerCoachingSessionFragment = {
  __typename?: 'CoachingSession';
  id: string;
  startAt: number;
  endAt: number;
  content:
    | {
        __typename?: 'Class';
        id: string;
        name?: string | null;
        slug?: string | null;
        isFreeEvent?: boolean | null;
      }
    | { __typename?: 'OneOnOneSession'; title: string };
  coach: {
    __typename?: 'Coach';
    id: string;
    user: { __typename?: 'User'; id: string; firstName: string };
  };
};

export const CoachingSessionAlertBannerClassFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_Class on Class {
    id
    name
    slug
    isFreeEvent
  }
`;
export const CoachingSessionAlertBannerOneOnOneSessionFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_OneOnOneSession on OneOnOneSession {
    title
  }
`;
export const CoachingSessionAlertBannerCoachingSessionFragmentDoc = gql`
  fragment CoachingSessionAlertBanner_CoachingSession on CoachingSession {
    id
    startAt
    endAt
    content {
      ...CoachingSessionAlertBanner_Class
      ...CoachingSessionAlertBanner_OneOnOneSession
    }
    coach {
      id
      user {
        id
        firstName
      }
    }
  }
  ${CoachingSessionAlertBannerClassFragmentDoc}
  ${CoachingSessionAlertBannerOneOnOneSessionFragmentDoc}
`;
