import { IconSparkle } from '@leland-dev/leland-ui-library';
import React, { useCallback } from 'react';

import { wasMutationSuccessful } from '../../utils/mutations';
import SwitchInput from '../inputs/SwitchInput';

import {
  useGenerateSessionSummaryToggleUpdateUserMutation,
  useGenerateSessionSummaryToggleUserQuery,
} from './__generated-gql-types__/GenerateSessionSummaryToggle.generated';

interface SwitchSectionProps {
  mainHeading: string;
  label: string;
  subLabel: string;
  showDivider?: boolean;
}

export const GenerateSessionSummaryToggle: React.FC<SwitchSectionProps> = ({
  mainHeading,
  label,
  subLabel,
  showDivider = true,
}) => {
  const { data } = useGenerateSessionSummaryToggleUserQuery();

  const user = data?.user;

  const optOutTranscriptionValue = !!user?.optOutTranscription;

  const [updateUser] = useGenerateSessionSummaryToggleUpdateUserMutation();

  const handleOptOutToggle = useCallback(async () => {
    if (!user) {
      return;
    }
    try {
      const optOutTranscription = !optOutTranscriptionValue;
      const res = await updateUser({
        variables: {
          user: {
            optOutTranscription,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUser: {
            ...user,
            __typename: 'User',
            optOutTranscription,
          },
        },
      });

      if (!wasMutationSuccessful(res)) {
        console.warn(res.errors);
        throw new Error('Failed to update user');
      }
    } catch (e) {
      console.warn(e);
    }
  }, [optOutTranscriptionValue, updateUser, user]);

  return (
    <div>
      {showDivider ? (
        <div className="flex w-full flex-col space-y-6 border-t border-leland-gray-stroke py-4"></div>
      ) : null}
      <div className="flex items-start">
        <div className="mb-[24px] flex w-full flex-col space-y-2">
          <div className="flex items-center gap-2">
            <IconSparkle className="size-4.5" />
            <div className="flex w-full items-center justify-between space-x-2 text-lg font-medium leading-6 text-leland-gray-dark">
              <h3>{mainHeading}</h3>
            </div>
          </div>

          <p className="text-lg leading-6 text-leland-gray-light">{label}</p>

          <p className="text-lg leading-6 text-[#9B9B9B]">{subLabel}</p>
        </div>
        <SwitchInput
          className=""
          label=""
          isChecked={!optOutTranscriptionValue}
          onToggle={handleOptOutToggle}
        />
      </div>
    </div>
  );
};
